var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.tipsShow,
          expression: "tipsShow",
        },
      ],
      staticClass: "model",
    },
    [
      _c("div", { staticClass: "title" }, [
        _c(
          "div",
          {
            staticClass: "open",
            class: _vm.show ? "show" : "hide",
            on: { click: _vm.look },
          },
          [
            _c("svg", { staticClass: "iconpark-icon" }, [
              _c("use", { attrs: { href: "#send-one" } }),
            ]),
            _c("span", [_vm._v("使用指南")]),
          ]
        ),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show",
              },
            ],
            staticClass: "away",
            on: { click: _vm.look },
          },
          [_vm._v("收起")]
        ),
      ]),
      _c("transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show",
              },
            ],
            staticClass: "content",
            class: _vm.show ? "contentShow" : "contentHide",
          },
          [_c("div", { staticClass: "bg-img" }), _vm._t("content")],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }